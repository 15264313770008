import { createDecoratedMagicEvent } from '@/features/events/lib/createDecoratedMagicEvent';
import type {
  DecoratedMagicEvent,
  MagicEvent,
} from '../../../../features/magic/types';
import type { GetQuickEntriesPageQuery } from './useGetQuickEntriesPage.generated';

type QuickEntry =
  GetQuickEntriesPageQuery['getQuickEntriesPage']['quickEntries'][number];

const toISODate = (str: string): string => {
  return str.replace(/T.*$/, '');
};

export const convertQuickEntryToMagicEvent = (
  quickEntry: QuickEntry
): DecoratedMagicEvent => {
  const { startDate, endDate, isOnDay, rule } = quickEntry.recurrences[0];

  const magicEvent: MagicEvent = {
    ...quickEntry,
    title: quickEntry.title || '',
    rrule: rule,
    isAllDay: isOnDay,
    start: {
      date: isOnDay ? toISODate(startDate) : null,
      dateTime: isOnDay ? null : startDate,
      timeZone: isOnDay ? null : quickEntry.timeZone,
    },
    end: {
      date: isOnDay ? toISODate(endDate) : null,
      dateTime: isOnDay ? null : endDate,
      timeZone: isOnDay ? null : quickEntry.timeZone,
    },
  };

  return createDecoratedMagicEvent(magicEvent);
};
