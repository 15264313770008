import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { validate as validateUuid } from 'uuid';
import { gqlClient, queryClient } from '@/lib';
import type { QuickEntriesPageBy } from '@/types/gql.generated';
import type { QueryError } from '@/utils/errors';
import { createMagicJobPageQueryKey } from '@/utils/queryKeys';
import type {
  DecoratedMagicJob,
  DecoratedQuickCreateEntryPage,
} from '../../../../features/magic/types';
import type {
  GetQuickEntriesPageQuery,
  GetQuickEntriesPageQueryVariables,
} from './useGetQuickEntriesPage.generated';
import { convertQuickEntryToMagicEvent } from './utils';

const query = gql`
  query GetQuickEntriesPage($by: QuickEntriesPageBy!) {
    getQuickEntriesPage(by: $by) {
      title
      description
      image
      socialSharingEnabled
      quickEntries {
        id
        title
        emoji
        description
        timeZone
        locationWithPlace {
          name
          googlePlaceId
        }
        recurrences {
          startDate
          endDate
          isOnDay
          rule
        }
      }
    }
  }
`;

type ReturnValue = {
  job: DecoratedMagicJob;
  page: DecoratedQuickCreateEntryPage;
};

const selector = (data: GetQuickEntriesPageQuery): ReturnValue => {
  const { quickEntries, ...page } = data.getQuickEntriesPage;
  const events = quickEntries?.map(convertQuickEntryToMagicEvent) ?? [];
  const job = { events };

  return {
    job,
    page,
  };
};

export const useGetQuickEntriesPage = (
  dataSource: 'magic' | 'platform',
  slugOrId: string | undefined
) => {
  const queryKey = createMagicJobPageQueryKey(slugOrId);

  const { data, ...rest } = useQuery<
    GetQuickEntriesPageQuery,
    QueryError,
    ReturnValue
  >({
    enabled: Boolean(slugOrId),
    queryKey,
    select: selector,
    queryFn: () => {
      const by: QuickEntriesPageBy = {};
      const isUuid = slugOrId && validateUuid(slugOrId);

      if (dataSource === 'magic' && isUuid) {
        by.quickEntriesJobId = slugOrId;
      } else if (dataSource === 'magic') {
        by.quickEntriesJobSlug = slugOrId;
      } else if (dataSource === 'platform' && isUuid) {
        by.scheduleId = slugOrId;
      } else if (dataSource === 'platform') {
        by.scheduleSlug = slugOrId;
      }

      return gqlClient.request<
        GetQuickEntriesPageQuery,
        GetQuickEntriesPageQueryVariables
      >(query, { by });
    },
  });

  const reset = useCallback(() => {
    queryClient.resetQueries({ queryKey });
  }, [queryKey]);

  return {
    reset,
    ...data,
    ...rest,
  };
};
